// Constants
const imgView = document.getElementById('img-view');
const imgViewImg = imgView.getElementsByTagName('img')[0];

// Calc copyright year
const elem = document.getElementById('copyright');
elem.innerText = 'Copyright ' + new Date().getFullYear();

for (const img of Array.from(document.getElementsByTagName('img'))) {
    img.title = img.alt;
}

// Functions
function decryptEmail(encoded: string) {
    const address = atob(encoded);
    window.location.href = "mailto:" + address;
}

function hideImgView() {
    imgView.classList.remove('show');
}

function showImgView(img : HTMLImageElement) {
    imgViewImg.src = img.src;
    imgViewImg.alt = img.alt;
    imgViewImg.title = img.title;
    imgView.classList.add('show');
}

// Img View
imgView.addEventListener('click', () => {
    hideImgView();
});

document.querySelectorAll(".gallery img").forEach(img => {
    img.addEventListener('click', () => {
        showImgView(img as HTMLImageElement);
    });
});
